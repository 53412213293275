import React, { useState, useEffect } from "react"
import { BanknotesIcon, CalendarDaysIcon, ChartBarIcon, CurrencyDollarIcon, UsersIcon, LinkIcon, MegaphoneIcon, ReceiptPercentIcon, TableCellsIcon, TrophyIcon, ClipboardDocumentCheckIcon, ArrowTrendingUpIcon, SparklesIcon, PencilIcon, ViewfinderCircleIcon, TrashIcon } from "@heroicons/react/24/outline"
import DashCard from "./PartnerDashboard/DashCard"
import { NavLink, useNavigate } from "react-router-dom";
import promo from "../../assets/images/partner/promo/promo.png";
import promo_1 from "../../assets/images/partner/promo/promo_1.png";
import promo_2 from "../../assets/images/partner/promo/promo_2.png";
import Regional_Sales_Manager from "../../assets/images/partner/leader_batch/Regional_Sales_Manager.svg";
import Managing_Director from "../../assets/images/partner/leader_batch/Managing_Director.svg";
import Country_Head from "../../assets/images/partner/leader_batch/Country_Head.svg";
import Sales_Director from "../../assets/images/partner/leader_batch/Sales_Director.svg";
import District_Sales_Manager from "../../assets/images/partner/leader_batch/District_Sales_Manager.svg";
import Sales_Manager from "../../assets/images/partner/leader_batch/Sales_Manager.svg";
import wallet from "../../assets/images/partner/wallet.svg";
import PaymentStatistics from "./PartnerDashboard/PaymentStatistics";
import { LockOpenIcon, LockClosedIcon, CheckCircleIcon, ShieldCheckIcon, CheckBadgeIcon, ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import Config from "../../Config";
import axios from "axios";
import { NumberFormat } from "./General/Functions";
import { toast, ToastContainer } from "react-toastify";
import LoginCheck from "./General/LoginCheck";
import { Carousel, Modal, Spinner } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import moment from "moment";

const BusinessDashboard = (props) => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberPhoto, setMemberPhoto] = useState(localStorage.getItem("Img_File"));
    const [loading, setLoading] = useState(false);
    const [MemberInfo, setMemberInfo] = useState({});
    const [placementUpdated, setPlacementUpdated] = useState(false);
    const [BarShape, setBarShape] = useState('Bar');
    const [Day, setDay] = useState('1');
    const [NoOfPackages, setNoOfPackages] = useState(0);
    const [PackageValue, setPackageValue] = useState(0);
    const [HighestPackage, setHighestPackage] = useState("");
    const [Pool, setPool] = useState("");
    const [LeaderData, setLeaderData] = useState("");
    const [Target_id, setTarget_id] = useState(0);
    const [TargetLoading, setTargetLoading] = useState(false);
    const [NextID, setNextID] = useState(0);
    const [PreviousID, setPreviousID] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);


    const refUrl = window.location.origin + '/signup/' + MemberInfo.Member?.User_Name;

    let navigate = useNavigate();


    const promoImages = [
        { src: promo, alt: "promo" },
        // { src: promo_1, alt: "promo_1" }
        // { src: promo_2, alt: "promo_2" },
    ];


    useEffect(() => {

        setLoading(true);
        setPlacementUpdated(false);
        const Parms = {
            AccessKey: AccessKey,
            UserID: UserID,
            GraphType: Day,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetDashboardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    if (response.data.Member?.Email_Address_Verified == true) {
                        setMemberInfo(response.data);
                        setNoOfPackages(response.data.NoOfPackages);
                        setPackageValue(response.data.PackageValue);
                        setHighestPackage(response.data.HighestPackage);
                        setPool(response.data.ListofPools);
                    } else if (response.data.status_message === "Invalid Access Key.") {
                        navigate("/login");
                    }
                    else {
                        navigate('/signup/verify-email');
                    }


                    setLoading(false);
                }


            })
            .catch(function (error) {
            });



    }, [placementUpdated, Day]);

    useEffect(() => {

        setTargetLoading(true);
        const Parms = {
            AccessKey: AccessKey,
            UserID: UserID,
            CurrentID: Target_id,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetLeaderStatistics',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setLeaderData(response.data)
                    setNextID(response.data.NextID);
                    setPreviousID(response.data.PreviousID);
                    setTargetLoading(false);
                }
                else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/login");
                    localStorage.clear();
                } else {
                    setTargetLoading(false);
                }


            })
            .catch(function (error) {
            });



    }, [Target_id]);

    const renderPool = () => {
        if (Pool != null && Pool.length > 0) {
            return (
                Pool.map((item, index) => {
                    return (
                        <>
                            <li className={`${item.PoolStatus == "Unlocked" ? 'open' : 'close'} ${index == 0 ? 'first-pool' : ''}`} key={index}>
                                <div className={`info ${item.PoolStatus == "Unlocked" ? 'active' : ''}`}>
                                    <label htmlFor="">Pool {item.ID}</label>
                                    <h1>{item.Name}</h1>
                                    <div className={`icon ${item.PoolStatus == ("Unlocked") ? 'open' : 'close'}`}>
                                        {item.PoolStatus == "Unlocked" ? <LockOpenIcon /> :
                                            item.PoolStatus == "Locked" ?
                                                <LockClosedIcon />
                                                :
                                                item.PoolStatus == "Achieved" ?
                                                    <ShieldCheckIcon />
                                                    :
                                                    ''
                                        }

                                    </div>
                                </div>
                                <div className="detail">
                                    <svg width="100" height="20" viewBox="0 0 100 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M87 0H13V8C13 14.6274 18.3726 20 25 20H75C81.6274 20 87 14.6274 87 8V0Z" fill="#FF002B" />
                                        <path d="M26.04 14L24.288 10.82H23.796V14H21.744V5.576H25.188C25.852 5.576 26.416 5.692 26.88 5.924C27.352 6.156 27.704 6.476 27.936 6.884C28.168 7.284 28.284 7.732 28.284 8.228C28.284 8.788 28.124 9.288 27.804 9.728C27.492 10.168 27.028 10.48 26.412 10.664L28.356 14H26.04ZM23.796 9.368H25.068C25.444 9.368 25.724 9.276 25.908 9.092C26.1 8.908 26.196 8.648 26.196 8.312C26.196 7.992 26.1 7.74 25.908 7.556C25.724 7.372 25.444 7.28 25.068 7.28H23.796V9.368ZM32.6241 7.22V8.924H35.3721V10.508H32.6241V12.356H35.7321V14H30.5721V5.576H35.7321V7.22H32.6241ZM49.6563 5.576L47.4603 14H44.9763L43.6323 8.456L42.2403 14H39.7563L37.6203 5.576H39.8163L41.0283 11.708L42.5283 5.576H44.7843L46.2243 11.708L47.4483 5.576H49.6563ZM56.9294 12.512H53.7854L53.2814 14H51.1334L54.1814 5.576H56.5574L59.6054 14H57.4334L56.9294 12.512ZM56.4014 10.928L55.3574 7.844L54.3254 10.928H56.4014ZM65.8291 14L64.0771 10.82H63.5851V14H61.5331V5.576H64.9771C65.6411 5.576 66.2051 5.692 66.6691 5.924C67.1411 6.156 67.4931 6.476 67.7251 6.884C67.9571 7.284 68.0731 7.732 68.0731 8.228C68.0731 8.788 67.9131 9.288 67.5931 9.728C67.2811 10.168 66.8171 10.48 66.2011 10.664L68.1451 14H65.8291ZM63.5851 9.368H64.8571C65.2331 9.368 65.5131 9.276 65.6971 9.092C65.8891 8.908 65.9851 8.648 65.9851 8.312C65.9851 7.992 65.8891 7.74 65.6971 7.556C65.5131 7.372 65.2331 7.28 64.8571 7.28H63.5851V9.368ZM73.5172 5.576C74.4052 5.576 75.1812 5.752 75.8452 6.104C76.5092 6.456 77.0212 6.952 77.3812 7.592C77.7492 8.224 77.9332 8.956 77.9332 9.788C77.9332 10.612 77.7492 11.344 77.3812 11.984C77.0212 12.624 76.5052 13.12 75.8332 13.472C75.1692 13.824 74.3972 14 73.5172 14H70.3612V5.576H73.5172ZM73.3852 12.224C74.1612 12.224 74.7652 12.012 75.1972 11.588C75.6292 11.164 75.8452 10.564 75.8452 9.788C75.8452 9.012 75.6292 8.408 75.1972 7.976C74.7652 7.544 74.1612 7.328 73.3852 7.328H72.4132V12.224H73.3852Z" fill="white" />
                                        <path d="M13 0C13 0 -7 0 3 0C13 0 13 10 13 10V0Z" fill="#FF002B" />
                                        <path d="M87 0C87 0 107 0 97 0C87 0 87 10 87 10V0Z" fill="#FF002B" />
                                    </svg>

                                    <div className="head">
                                        <div className="image">
                                            <img src={Config.path + 'Images/pools/pool_' + item.ID + '.png'} alt="pool image" />
                                        </div>
                                        <h1>{item.IsCashReward ? 'PKR' : ''} {item.PoolReward}</h1>
                                    </div>
                                    <div className="content-body">
                                        <h6>Team Criteria (Paid Members)</h6>
                                        <ul>
                                            <li>
                                                <label htmlFor="">Required Members </label>
                                                <span>{item.RequredMembers}</span>
                                            </li>
                                            <li>
                                                <label htmlFor="">Current Members </label>
                                                <span>{item.CurrentMembers}</span>
                                            </li>
                                            <li>
                                                <label htmlFor="">Remaining Member</label>
                                                <span>{item.RemainingMembers}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li >
                        </>
                    )
                })
            )
        }
    }


    const DeleteImageSelected = () => {
        setImageSelected(false);
        setImageCropped(false)
    }

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }


    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        setLoading(true);
        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);


            var cleanerBase64 = cropData1.substring(22);


            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                Img_File: cleanerBase64
            };



            var api_config = {
                method: 'post',
                url: Config.base_url + 'Members/UpdatePhoto',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {


                    if (response.data.status_code == 1) {
                        setIsUpdated(true);
                        setImageSelected(false);
                        localStorage.removeItem("Img_File");
                        localStorage.setItem("Img_File", UserID + ".JPG");
                        window.location.reload();
                    }
                    else {
                        setIsUpdated(false);
                    }

                    setLoading(false);

                })
                .catch(function (error) {
                });

        }
    };

    const renderButtons = () => {
        if (imageSelected === true) {
            return (
                <>
                    <button className="button button button-outline-bs" onClick={handleCloseModal}>Close</button>
                    <button className="button button-bs" disabled={loading} onClick={getCropData}>Save Photo {loading ? '...' : ''}</button>
                </>
            )
        }
        else if (imageSelected === false && isUpdated === true) {
            return (
                <>
                    <button className="button button button-outline-bs" onClick={handleCloseModal}>Close</button>
                </>
            )
        }
    }

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <>
            <LoginCheck />
            <ToastContainer />

            <Modal show={showModal} onHide={handleCloseModal} className="upload-img-model">
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-img model-pink">
                        <div className="upload-box">
                            <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                            {
                                isUpdated ?
                                    <>
                                        <div className="text-center">
                                            {/* <img src={ImgDone} style={{ maxWidth: "300px" }} /> */}
                                            <p>Photo Updated Successfully</p>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="upload-photo-wrap">
                                            {
                                                imageSelected ?
                                                    <>
                                                        <Cropper
                                                            zoomTo={0.5}
                                                            aspectRatio={8 / 8}
                                                            preview=".img-preview"
                                                            src={image}
                                                            dragMode="move"
                                                            viewMode={2}
                                                            minCropBoxHeight={10}
                                                            minCropBoxWidth={10}
                                                            background={false}
                                                            responsive={true}
                                                            autoCropArea={1}
                                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                            onInitialized={(instance) => {
                                                                setCropper(instance);
                                                            }}
                                                            guides={true}
                                                        />
                                                        <div className="delete" onClick={DeleteImageSelected}>
                                                            <TrashIcon />
                                                        </div>
                                                    </>
                                                    :
                                                    <label htmlFor="ChoosePhoto" className="thumnail">
                                                        <ViewfinderCircleIcon />
                                                        <h6>Browse Photo</h6>
                                                    </label>
                                            }
                                        </div>
                                    </>

                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        {renderButtons()}
                    </div>
                </Modal.Body>
            </Modal>

            <div className="section-title">Dashboard</div>
            <section className="dash-bg-card profile-section mb-4">
                <div className="heading m-md-0">MY Profile</div>
                <div className="row align-items-center g-3">
                    <div className="col-md-4">
                        <div className="profile">
                            <div className="profile_header">
                                <div className="profile_photo" onClick={handleShowModal}>
                                    <img src={Config.img_path + MemberPhoto} alt="Profile Photo" />
                                    <div className="profile_edit">
                                        <PencilIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                <div className="info">
                                    <h1>{MemberInfo.Member?.Full_Name}</h1>
                                    <p>User ID: {MemberInfo.Member?.User_Name}</p>
                                </div>
                                <ul>
                                    <li className="d-none"><img src={Config.path + 'Images/Ranks/' + MemberInfo.Member?.RankName + '.png'} alt="" /></li>
                                    <li><p>{MemberInfo.Member?.Member_Type == "Standard" ? 'Free' : 'Paid'}</p></li>
                                    <li>
                                        {MemberInfo.Member?.KYCStatus === 'Under Review' ?
                                            <ClockIcon />
                                            :
                                            MemberInfo.Member?.KYCStatus === 'Verified' ?
                                                <CheckBadgeIcon />
                                                :
                                                <ExclamationCircleIcon />

                                        }
                                        <p>{MemberInfo.Member?.KYCStatus}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link">
                            <span>Invite Friends</span>
                            <div>
                                <span>{refUrl}</span>
                                <LinkIcon
                                    onClick={() => {
                                        let url = window.location.origin + '/signup/' + MemberInfo.Member?.User_Name;
                                        navigator.clipboard.writeText(url);
                                        toast.success('Link Copied', {
                                            position: toast.POSITION.TOP_RIGHT
                                        });

                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <ul className="profile-card-list">
                            <li>
                                <div className="info-icon-list">
                                    <div className="top">
                                        <label htmlFor="">My Rank</label>
                                        <div className="icon orange">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M21 7.5L12 2.25L3 7.5M21 7.5L12 12.75M21 7.5V16.5L12 21.75M3 7.5L12 12.75M3 7.5V16.5L12 21.75M12 12.75V21.75" stroke="#FF6040" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="rank-content">
                                        <div className="active-rank">
                                            <div className="rank-icon">
                                                <img src={Config.path + 'Images/Ranks/' + MemberInfo.Member?.RankName + '.png'} alt="" />
                                            </div>
                                            <div className="rank-info">
                                                <span>Current</span>
                                                <h6>{MemberInfo.Member?.RankName}</h6>
                                            </div>
                                        </div>
                                        <div className="pending-rank">
                                            <div className="rank-icon">
                                                <img src={Config.path + 'Images/Ranks/' + MemberInfo?.nextRankName + '.png'} alt="" />
                                            </div>
                                            <div className="rank-info">
                                                <span>Next</span>
                                                <h6>{MemberInfo.nextRankName}</h6>
                                            </div>
                                            <div className="task">
                                                {/* <div className="rank-progress">
                                                    <div style={{ width: `${MemberInfo.nextRankPercent}%` }}></div>

                                                </div>
                                                 <span>{MemberInfo.nextRankPercent}%</span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="info-icon-list">
                                    <div className="top">
                                        <label htmlFor="">Active Packages</label>
                                        <div className="icon pink">
                                            <TrophyIcon />
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <label htmlFor="">Packages :</label>
                                            <span>{NoOfPackages}</span>
                                        </li>
                                        <li>
                                            <label htmlFor="">Highest :</label>
                                            <span>{HighestPackage}</span>
                                        </li>
                                        <li>
                                            <label htmlFor="">Value :</label>
                                            <span>PKR {NumberFormat(PackageValue)}</span>
                                        </li>
                                    </ul>

                                </div>
                            </li>

                            <li>
                                <div className="info-icon-list">
                                    <div className="top">
                                        <label htmlFor="">Overview</label>
                                        <div className="icon red">
                                            <ClipboardDocumentCheckIcon />
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <label htmlFor="">Earning :</label>
                                            <span>PKR {NumberFormat(MemberInfo.TotalEarnings)} </span>
                                        </li>
                                        <li>
                                            <label htmlFor="">Deposit :</label>
                                            <span>PKR {NumberFormat(MemberInfo.TotalDeposit)}</span>
                                        </li>
                                        <li>
                                            <label htmlFor="">Withdrawal :</label>
                                            <span>PKR {NumberFormat(MemberInfo.TotalWithdraw)}</span>
                                        </li>
                                        <li>
                                            <label htmlFor="">Transfer :</label>
                                            <span>PKR {NumberFormat(MemberInfo.TotalTrasnfers)}</span>
                                        </li>
                                    </ul>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {MemberInfo?.Member?.IsLeader && LeaderData.status_message != "No Target exists." ?
                <section className="dash-bg-card target-section mb-4">
                    <div className="heading m-md-0">Sale Target</div>
                    {TargetLoading ?
                        <div className="text-center my-5">
                            <Spinner animation="border" role="status"></Spinner>
                        </div>
                        :
                        <>

                            <div className="target-header">
                                <div className="target-info left">
                                    <p>designation</p>
                                    <h4>{(LeaderData.DesignationName)}</h4>
                                </div>
                                <h1></h1>
                                <div className="polygon">
                                    <img
                                        src={
                                            LeaderData.DesignationName === 'Regional Sales Manager'
                                                ? Regional_Sales_Manager
                                                : LeaderData.DesignationName === 'Sales Director'
                                                    ? Sales_Director
                                                    : LeaderData.DesignationName === 'Managing Director'
                                                        ? Managing_Director
                                                        : LeaderData.DesignationName === 'District Sales Manager'
                                                            ? District_Sales_Manager
                                                            : LeaderData.DesignationName === 'Sales Manager'
                                                                ? Sales_Manager : Country_Head

                                        }
                                        style={
                                            LeaderData.DesignationName === 'Regional Sales Manager'
                                                ? { transform: 'translate(-50%, 30%)' }
                                                : LeaderData.DesignationName === 'Sales Director'
                                                    ? { transform: 'translate(-50%, 44%)' }
                                                    : LeaderData.DesignationName === 'Managing Director'
                                                        ? { transform: 'translate(-50%, 30%)' }
                                                        : LeaderData.DesignationName === 'District Sales Manager'
                                                            ? { transform: 'translate(-50%, 33%)' }
                                                            : LeaderData.DesignationName === 'Sales Manager'
                                                                ? { transform: 'translate(-50%, 28%)' } :
                                                                { transform: 'translate(-50%, 73%)' }
                                        }
                                        alt={`${LeaderData.DesignationName} Batch`}
                                    />

                                </div>
                                <div className="target-info right">
                                    <p>Leadership Earned</p>
                                    <h4>PKR {NumberFormat(LeaderData.totalEarnings)}</h4>
                                </div>
                            </div>
                            <div className="row align-items-center gy-3">
                                <div className="col-md-2">
                                    <button className="arrow-target text-end" disabled={PreviousID == 0} onClick={() => setTarget_id(PreviousID)}>
                                        <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 0V17L0 8.5L12 0Z" fill="#D9D9D9" />
                                        </svg>
                                        <span>
                                            Previous Month Target
                                        </span>
                                    </button>
                                </div>
                                <div className="col-md-8">
                                    <div className="content-bg">
                                        <div className="content-info left">

                                            <div className="content">
                                                <p><span>{LeaderData.achievedPercentage}%</span>Achieved</p>
                                                <h3>PKR {NumberFormat(LeaderData.AchievedTarget)}</h3>
                                                <label>Start Date {moment(LeaderData.startDate).format('L')}</label>
                                            </div>
                                        </div>
                                        <div className="target-graph">
                                            <div className="ellipse">
                                                <svg className="svg-ellipse-chart" viewBox="0 0 33.83098862 33.83098862" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                                                    <defs>
                                                        <linearGradient id="linear-color" x1="71.5" y1="71.5" x2="-2.86102e-06" y2="2.86102e-06" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FF1500" />
                                                            <stop offset="0.5" stopColor="#FF002B" />
                                                            <stop offset="1" stopColor="#FF4060" />
                                                        </linearGradient>
                                                    </defs>
                                                    <circle className="svg-ellipse-chart-background" stroke="#fff" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    <circle className="svg-ellipse-chart-circle" stroke="url(#linear-color)" strokeWidth="2" strokeDasharray={`${LeaderData.achievedPercentage},100`} strokeLinecap="round" fill="#fff" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    <g className="svg-ellipse-chart-info">
                                                        <text className="top-line" x="16.91549431" y="12.5" alignmentBaseline="middle" textAnchor="middle" fontSize="2.5">Target PKR</text>
                                                        <text className="center-line" x="16.91549431" y="16.91549431" alignmentBaseline="middle" textAnchor="middle" fontSize="4" fontWeight='600' fill="#FE1C3B">{NumberFormat(LeaderData.TargetAmount)}</text>
                                                        <text className="bottom-line" x="16.91549431" y="21.5" alignmentBaseline="middle" textAnchor="middle" fontSize="2.5" ><tspan font-weight="bold">({LeaderData.CommissionPercentage}%)</tspan> Leadership</text>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="content-info right">
                                            <div className="content">
                                                <p>Remaining<span>{LeaderData.remainingPercentage}%</span></p>
                                                <h3>PKR {NumberFormat(LeaderData.RemainingTarget)}</h3>
                                                <label>End Date {moment(LeaderData.endDate).format('L')}</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <button className="arrow-target" disabled={NextID == 0} onClick={() => setTarget_id(NextID)}>
                                        <span>
                                            Next Month Target
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
                                            <path d="M1.19209e-07 0V17L12 8.5L1.19209e-07 0Z" fill="#D9D9D9" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                </section >
                :
                ''
            }
            <div className="row gy-3 align-items-center mb-4">
                <div className="col-md-4">
                    <div className="dashboard_wallets dash-bg-card">
                        <h4>MY WALLETS</h4>
                        <div className="thumnail">
                            <img src={wallet} alt="" />
                            <div className="content">
                                <ul>
                                    <li>
                                        <span>MAG Wallet</span>
                                        <h3>PKR {NumberFormat(MemberInfo.Member?.Current_Balance)}</h3>
                                    </li>
                                    <li>
                                        <span>Holding Wallet</span>
                                        <h3>PKR {NumberFormat(MemberInfo.Member?.MagWallet)}</h3>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="d-flex justify-content-between my-3">
                            <NavLink to="/partner/addmoney" className="button button-bs">
                                Deposit
                            </NavLink>
                            <Link to={'/partner/balance-transfer'} className="button button-bs">
                                Transfer
                            </Link>
                            <NavLink to={'/partner/withdraw'} className="button button-bs">
                                Withdraw
                            </NavLink>
                        </div>
                        <ul className="list_wallets">
                            <li className="dash-card pink">
                                <div>
                                    <h5>Profit Wallet</h5>
                                    <p>PKR {NumberFormat(MemberInfo.ProfitWallet)}</p>
                                </div>
                                <div className="icon">
                                    <BanknotesIcon />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="earning-card dash-bg-card">
                        <div className="row align-items-end">
                            <div className="col-md-12">
                                <div className="dash-header">
                                    <div className="heading">
                                        Earning Statistics
                                    </div>
                                    <div className="menu">
                                        <ul className="menu-link">
                                            <li><a className={Day == "4" ? "active" : ""} onClick={(e) => setDay("4", e)}>Today</a></li>
                                            <li><a className={Day == "3" ? "active" : ""} onClick={(e) => setDay("3", e)}>This Week</a></li>
                                            <li><a className={Day == "2" ? "active" : ""} onClick={(e) => setDay("2", e)}>Last 15 Days</a></li>
                                            <li><a className={Day == "1" ? "active" : ""} onClick={(e) => setDay("1", e)}>Last Month</a></li>
                                            <li>
                                                <button className="" onClick={(e) => {
                                                    const picker1 = document.getElementById("header-date");
                                                    picker1.showPicker();
                                                }}>
                                                    <CalendarDaysIcon />
                                                </button>
                                                <input
                                                    type="date"
                                                    id="header-date"
                                                    name="header-date"
                                                    className="menu-date"
                                                    placeholder="DD/MM/YY"
                                                />
                                            </li>
                                        </ul>
                                        <ul className="menu-icon">
                                            {/* <li><a className={BarShape == "Cell" ? "active" : ""} onClick={(e) => setBarShape("Cell", e)} href="#"><TableCellsIcon /></a></li> */}
                                            <li><a className={BarShape == "Bar" ? "active" : ""} onClick={(e) => setBarShape("Bar", e)} href="#"><ChartBarIcon /></a></li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="bar-background">
                                    <PaymentStatistics loading={loading} GraphData={MemberInfo} GraphType={Day} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row gy-3 align-items-center mb-4 bs-promo-section">
                <div className="col-md-8">
                    <div className="bs-package">
                        <Carousel
                            // controls={false} indicators={false}
                            className="promo-slider"
                            activeIndex={index}
                            onSelect={handleSelect}
                        >
                            {promoImages.map((promo, idx) => (
                                <Carousel.Item key={idx}>
                                    <Link to={`/partner/packages`}>
                                        <img
                                            src={promo.src}
                                            className="img-fluid rounded-4"
                                            alt={promo.alt}
                                            style={{ width: '100%' }}
                                        />
                                    </Link>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="dash-bg-card">
                        <div className="bs-package">
                            <div className="heading">
                                My Referral's
                            </div>
                            <ul className="team-list">
                                <li>
                                    <DashCard
                                        link='/partner/network'
                                        icon={<UsersIcon />}
                                        value={MemberInfo.TotalTeam}
                                        label="Total Team"
                                        color="pink"
                                        currency={null}
                                    />
                                </li>
                                <li>
                                    <DashCard
                                        link='/partner/network'
                                        icon={<UsersIcon />}
                                        value={NumberFormat(MemberInfo.TeamInvestment)}
                                        label="Team Sales"
                                        color="orange"
                                        currency="PKR"

                                    />
                                </li>
                                <li>
                                    <DashCard
                                        link='/partner/network'
                                        icon={<ArrowTrendingUpIcon />}
                                        value={MemberInfo.TotalDirect}
                                        label="Direct Team"
                                        color="orange"
                                        currency={null}
                                    />
                                </li>
                                <li>
                                    <DashCard
                                        link='/partner/network'
                                        icon={<SparklesIcon />}
                                        value={NumberFormat(MemberInfo.DirectInvestment)}
                                        label="Direct Team Sales"
                                        color="orange"
                                        currency="PKR"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>


            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="dash-bg-card ">
                        <div className="heading mb-3">Referral Program</div>
                        <ul className="dash-list">
                            <li>
                                <DashCard
                                    link='/partner/transactions/smart-wallet/direct-bonus'
                                    icon={<BanknotesIcon />}
                                    value={NumberFormat(MemberInfo.DirectBonus)}
                                    label="Direct Bonus"
                                    color="yellow"
                                />
                            </li>
                            <li>
                                <DashCard
                                    link='/partner/transactions/profit-wallet/tree-income'
                                    icon={<TrophyIcon />}
                                    value={NumberFormat(MemberInfo.RoyaltyIncome)}
                                    label="Royalty Income"
                                    color="pink"
                                />
                            </li>
                            <li>
                                <DashCard
                                    link='/partner/transactions/smart-wallet/referral-bonus'
                                    icon={<UsersIcon />}
                                    value={NumberFormat(MemberInfo.ReferralBonus)}
                                    label="Referral Bonus"
                                    color="red"
                                />
                            </li>


                            <li>
                                <DashCard
                                    link='/partner/transactions/profit-wallet/roi-income'
                                    icon={<ReceiptPercentIcon />}
                                    value={NumberFormat(MemberInfo.ReturnROI)}
                                    label="Profit Return"
                                    color="orange"
                                />
                            </li>
                            <li>
                                <DashCard
                                    link='/partner/transactions/smart-wallet/cashback-bonus'
                                    icon={<CurrencyDollarIcon />}
                                    value={NumberFormat(MemberInfo.CashbackBonus)}
                                    label="Cashback Bonus"
                                    color="pink"
                                />
                            </li>
                            <li>
                                <DashCard
                                    link='/partner/transactions/treasure-wallet/ranks-reward'
                                    icon={<ReceiptPercentIcon />}
                                    value={NumberFormat(MemberInfo.RewardBonus)}
                                    label="Rewards Income"
                                    color="yellow"
                                />
                            </li>
                            <li>
                                <DashCard
                                    link='/partner/transactions/treasure-wallet/pool-reward'
                                    icon={<CurrencyDollarIcon />}
                                    value={NumberFormat(MemberInfo.PoolReward)}
                                    label="Pool Reward"
                                    color="orange"
                                />
                            </li>
                            <li>
                                <DashCard
                                    link='/partner/transactions/treasure-wallet/promotion'
                                    icon={<MegaphoneIcon />}
                                    value={NumberFormat(MemberInfo.PromotionBonus)}
                                    label="Promotions"
                                    color="red"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row gy-3 pb-5">
                <div className="col-md-7">
                    <div className="pool-card dash-bg-card">
                        <div className="heading mb-3">MAG Millionaire</div>

                        <ul>
                            {renderPool()}
                        </ul>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="dash-bg-card">
                        <div className="heading mb-3">Royalty Income</div>
                        <div className="level-graph">
                            <ul>

                                {[...Array(7)].map((_, index) => (
                                    <li key={index} className={`${index + 1 == MemberInfo.RoyalityLevels ? 'active' : 'pending'}`}>
                                        <span>
                                            {
                                                index + 1 > MemberInfo.RoyalityLevels ?
                                                    <LockClosedIcon />
                                                    :
                                                    <CheckCircleIcon />
                                            }

                                        </span>
                                        <label htmlFor="">Level {index + 1} : {index == 0 ? '3' : index == 1 ? '2' : index == 2 ? '2' : index == 3 ? '2' : index == 4 ? '1' : index == 5 ? '2' : index == 6 ? '3' : '3'}%</label>
                                    </li>
                                ))}


                            </ul>
                            <div className="ellipse p-3">
                                <svg className="svg-ellipse-chart" viewBox="0 0 33.83098862 33.83098862" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="linear-color" x1="71.5" y1="71.5" x2="-2.86102e-06" y2="2.86102e-06" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FF1500" />
                                            <stop offset="0.5" stopColor="#FF002B" />
                                            <stop offset="1" stopColor="#FF4060" />
                                        </linearGradient>
                                    </defs>
                                    <circle className="svg-ellipse-chart-background" stroke="#fff" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                    <circle className="svg-ellipse-chart-circle" stroke="url(#linear-color)" strokeWidth="2" strokeDasharray={`${MemberInfo.RoyalityGraphPercentage},100`} strokeLinecap="round" fill="#fff" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                    <g className="svg-ellipse-chart-info">
                                        <text className="svg-ellipse-chart-percent" x="16.91549431" y="16.91549431" alignmentBaseline="middle" textAnchor="middle" fontSize="3" fontWeight='600'>Level {MemberInfo.RoyalityLevels} : {MemberInfo.RoyalityPercentage}%</text>
                                        <text className="svg-ellipse-chart-subline" x="16.91549431" y="20.5" alignmentBaseline="central" textAnchor="middle" fontSize="2">Level 7 : upto 15%</text>
                                    </g>
                                </svg>
                            </div>

                        </div>
                        <ul className="list">
                            <li>You must have an active package</li>
                            <li>Your Direct Referrals must have an active package.</li>
                            <li>7 Direct Referrals compulsory to open all levels, each Direct Referral will open your next royalty income level.</li>
                            <li>Royalty income from seven levels</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessDashboard